<template>

<div class="rulesMeals table-hook fadeInRight">
    <div class="title-box">
        <span class="m-title">规则套餐列表</span>
        <el-form inline class="hook right themed-form search-form" style="line-height: 40px;" :model="queryForm">
            <el-form-item label="套餐名称:">
                <el-input v-model="queryForm.name" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="searchTable">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-plus" class="themed-button" @click="addRecord">添加</el-button>
            </el-form-item>
             <el-form-item>
                <el-button type="primary" :disabled="!multiple" icon="el-icon-remove" class="themed-button" @click="removeRecord">删除</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="content-box">
        <el-table ref="table" :data="tableData" @selection-change="handleSelectionChange" stripe border :max-height="maxTableHeight">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column type="index" label="序号" width="70" align="center" :index="indexMethod"/>
            <el-table-column label="套餐名称" prop="mealName" width=""></el-table-column>
        </el-table>
    </div>
    <div class="pagination">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>

    <!-- 选择套餐话框 -->
       <el-dialog title="套餐列表" :visible.sync="dialogMealTable" width="65%" append-to-body>
         <el-form :model="setMealForm" ref="setMealForm" :inline="true" label-width="68px" class="dialogMealTable">
        <el-form-item label="套餐名称" prop="name" size="mini">
          <el-input
            v-model="setMealForm.name"
            placeholder="套餐名称"
            clearable
          />
        </el-form-item>
        <el-form-item size="mini" class="button">
          <el-button type="primary" icon="el-icon-search" @click="handleQuerySetMeal">搜索</el-button>
        </el-form-item>
    </el-form>

      <el-table v-loading="loading" :highlight-current-row="true" :data="setMealTableData" @selection-change="handleSelectionSetMealChange" style="max-height:580px;overflow-y:auto">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="套餐ID"  prop="id" show-overflow-tooltip v-if="false"/>
        <el-table-column label="套餐名称" prop="name" width="220px" show-overflow-tooltip></el-table-column>
        <el-table-column label="套餐流量" prop="flowSize">
            <template slot-scope="scope">
                <span>{{(scope.row.flowSize/1024).toFixed(2)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="套餐语音量" prop="voiceSize" show-overflow-tooltip></el-table-column>
        <el-table-column label="套餐类型" prop="typeString" width="180px" show-overflow-tooltip></el-table-column>
        <el-table-column label="所属类别" prop="category">
            <template slot-scope="scope">
                <span>{{categoryString(scope.row.category)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="状态" prop="statusString"></el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination
        @size-change="handleSetMealSizeChange"
        @current-change="handleSetMealCurrentChange"
        :current-page="setMealForm.pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="setMealForm.pageSize"
        background
        :total="setMealTotal">
        </el-pagination>
    </div>
    <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmSelectedSetMeal">确 定</el-button>
        <el-button @click="cancelSelectedSetMeal">取 消</el-button>
      </div>
    </el-dialog>
</div>

</template>

<script type="text/ecmascript-6">
import {rulesMealList,getSetMealList,saveRulesMealDetail,deleteRulesMeal} from '@/api/setMealManagment/mealRules.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import store from '@/store'

import resize from '@/mixins/resize.js'

export default {
    name:'roleMeal',
    mixins:[resize],
    data(){
        return {
            rulesId:'',
            mealIds:'',
            multiple:false,
            queryForm:{
                name:'',
            },
            tableData:[],
            pageNo:1,
            pageSize:15,
            total:0,
            dialogVisible:false,
            setMealTableData:[],
            setMealForm:{
                name:'',
                pageSize:15,
                pageNo:1
            },
            loading:false,
            dialogMealTable:false,
            setMealTotal:0,
            selectedSetMealIds:[],
        } 
    },
    mounted(){
        let params = this.$route.params;
        params.rulesId = params.rulesId;
        this.rulesId = params.rulesId;
        this._rulesMealList();
    },
    methods:{
         /** 计算table的行号*/
        indexMethod(index) {
            index = (index + 1) + (this.pageNo - 1) * this.pageSize
            return index
        },
        _rulesMealList(){
            let params = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                rulesId:this.rulesId,
                name :this.queryForm.name || ''
            }
            rulesMealList(params).then((res)=>{
                this.tableData = res.rows;
                this.total = res.total;
                this.pageNo = res.pageNo;
                this.pageSize = res.pageSize;
            }).catch(()=>{

            });
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.mealIds = selection.map(item => item.mealId);
            this.multiple = selection && selection.length > 0 ;
        },
        searchTable(){
            this._rulesMealList();
        },
        addRecord(){
            this.loading = true;
            this.showSetMealDialog();
        },
        removeRecord(){
            if(!this.mealIds || this.mealIds.length == 0){
                this.$alert('请选择一条数据', '提示', {
                    confirmButtonText: '确定',
                });
                return false
            }
            this.$confirm("确认要删除所选的数据吗？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    mealIds:this.mealIds.join(","),
                    rulesId:this.rulesId
                }
                deleteRulesMeal(params).then(res=>{
                    this._rulesMealList();
                    this.multiple = false;
                    this.mealIds = [];
                    this.$message({showClose: true,message: '删除成功',type: 'success',});
                }).catch(()=>{
                    this.multiple = false;
                    this.mealIds = [];
                    this.$message({showClose: true,message: '删除失败',type: 'error',});
                });
            }).catch(()=>{

            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._rulesMealList()
        },
        handleCurrentChange(val){
            this.pageNo = val
            this._rulesMealList()
        },   
        _setmealList(){
            getSetMealList(this.setMealForm).then((res)=>{
                this.loading = false;
                this.setMealForm.pageNo = res.pageNo
                this.setMealForm.pageSize = res.pageSize
                this.setMealTotal = res.total
                this.setMealTableData = res.rows
            }).catch(()=>{
                this.loading = false;
                this.dialogMealTable = false;
            });
        } ,
        handleSetMealSizeChange(val){
            this.setMealForm.pageSize  = val
            this._setmealList();
        },
        handleSetMealCurrentChange(val){
            this.setMealForm.pageNo = val
            this._setmealList();
        },   
        handleSelectionSetMealChange(selection) {
           this.selectedSetMealIds = selection.map(item => item.id);
        },
        handleQuerySetMeal(){
            this.setMealForm.pageNo = 1;
            this._setmealList();
        },
        showSetMealDialog(){
            this.dialogMealTable = true
            //初始化套餐选择对话框
            this.setMealForm = {
                name:'',
                pageSize:15,
                pageNo:1
            }
            this._setmealList();
        },
        confirmSelectedSetMeal(){
            if(!this.selectedSetMealIds || this.selectedSetMealIds.length == 0){
                this.$alert('请选择一条数据', '提示', {
                    confirmButtonText: '确定',
                });
                return false
            }
            let params = {
                rulesId:this.rulesId,
                mealIds:this.selectedSetMealIds.join(',')
            };
            saveRulesMealDetail(params).then((res)=>{
                this._rulesMealList();
                this.dialogMealTable = false;
                this.selectedSetMealIds = [];
                this.$message({showClose: true,message: '保存成功',type: 'success',});
            }).catch(()=>{
                this.selectedSetMealIds = [];
                this.dialogMealTable = false;
            });
        },
        cancelSelectedSetMeal(){
            this.selectedSetMealIds = [];
            this.dialogMealTable = false
        },
        categoryString(category){
            let cuCatagorys
            if(store.getters.categorys.length>0){
               store.getters.categorys.forEach(item=>{
                   if(category==item.value){
                     cuCatagorys = item.name
                   }
               })
            }
            return cuCatagorys
        },
    }
}

</script>
