import request from './../request.js'

export function mealRulesList(params){
    return request({
        url: 'mealrules/list.do?',
        method: 'post',
        data:params
    })
}
export function saveMealRules(params){  //添加
    return request({
        url: 'mealrules/save.do?',
        method: 'post',
        data:params
    })
}
export function updateMealRules(params){  //编辑
    return request({
        url: 'mealrules/update.do?',
        method: 'post',
        data:params
    })
}
export function deleteMealRules(params){ 
    return request({
        url: 'mealrules/deleteByIds.do?',
        method: 'post',
        data:params
    })
}

export function rulesMealList(params){
    return request({
        url: 'mealrules/detail/list.do?',
        method: 'post',
        data:params
    })
}
export function getSetMealList(params){
    return request({
        url: 'mealrules/detail/setMealList.do?',
        method: 'post',
        data:params
    })
}
export function saveRulesMealDetail(params){
    return request({
        url: 'mealrules/detail/saveMealRulesDetail.do?',
        method: 'post',
        data:params
    })
}
export function deleteRulesMeal(params){
    return request({
        url: 'mealrules/detail/deleteRulesMeal.do?',
        method: 'post',
        data:params
    })
}
export function getRulesUserList(params){
    return request({
        url: 'mealrules/user/list.do?',
        method: 'post',
        data:params
    })
}
export function getUserList(params){
    return request({
        url: 'mealrules/user/userList.do?',
        method: 'post',
        data:params
    })
}
export function saveRulesUser(params){
    return request({
        url: 'mealrules/user/saveRulesUser.do?',
        method: 'post',
        data:params
    })
}
export function deleteRulesUser(params){
    return request({
        url: 'mealrules/user/deleteRulesUser.do?',
        method: 'post',
        data:params
    })
}